import { BaseIconPathStyle, BaseSvgStyle } from '../IconBaseStyle';
import { ComponentProps } from '../SVGBase';

function Instagram({ size, color, title }: ComponentProps) {
  return (
    <BaseSvgStyle
      size={size}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      {title && <title>{title}</title>}
      <BaseIconPathStyle
        d="M12.0021 8.87579C10.2818 8.87579 8.87785 10.2797 8.87785 12C8.87785 13.7203 10.2818 15.1242 12.0021 15.1242C13.7224 15.1242 15.1263 13.7203 15.1263 12C15.1263 10.2797 13.7224 8.87579 12.0021 8.87579ZM21.3724 12C21.3724 10.7063 21.3841 9.42423 21.3114 8.13283C21.2388 6.63283 20.8966 5.30158 19.7997 4.2047C18.7005 3.10548 17.3716 2.76564 15.8716 2.69298C14.5778 2.62033 13.2958 2.63204 12.0044 2.63204C10.7107 2.63204 9.42863 2.62033 8.13722 2.69298C6.63722 2.76564 5.30597 3.10783 4.2091 4.2047C3.10988 5.30392 2.77003 6.63283 2.69738 8.13283C2.62472 9.42658 2.63644 10.7086 2.63644 12C2.63644 13.2914 2.62472 14.5758 2.69738 15.8672C2.77003 17.3672 3.11222 18.6985 4.2091 19.7953C5.30831 20.8945 6.63722 21.2344 8.13722 21.307C9.43097 21.3797 10.713 21.368 12.0044 21.368C13.2982 21.368 14.5802 21.3797 15.8716 21.307C17.3716 21.2344 18.7028 20.8922 19.7997 19.7953C20.8989 18.6961 21.2388 17.3672 21.3114 15.8672C21.3864 14.5758 21.3724 13.2938 21.3724 12V12ZM12.0021 16.807C9.34191 16.807 7.19503 14.6602 7.19503 12C7.19503 9.33986 9.34191 7.19298 12.0021 7.19298C14.6622 7.19298 16.8091 9.33986 16.8091 12C16.8091 14.6602 14.6622 16.807 12.0021 16.807ZM17.006 8.11876C16.3849 8.11876 15.8833 7.6172 15.8833 6.99611C15.8833 6.37501 16.3849 5.87345 17.006 5.87345C17.6271 5.87345 18.1286 6.37501 18.1286 6.99611C18.1288 7.14359 18.0999 7.28966 18.0435 7.42595C17.9872 7.56224 17.9045 7.68607 17.8002 7.79036C17.6959 7.89464 17.5721 7.97733 17.4358 8.03368C17.2995 8.09004 17.1535 8.11895 17.006 8.11876V8.11876Z"
        fill={color}
      />
    </BaseSvgStyle>
  );
}

export default Instagram;
